import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {Link} from "gatsby";
import especializamos from "../../../static/video/especializamos.mp4";
import transporte from "../../../static/images/lca/transporte11.jpg";
import telecomunicaciones from "../../../static/images/lca/telecomunicaciones11.jpg";
import social from "../../../static/images/lca/social.jpg";
import finanzas from "../../../static/images/lca/finanzas.jpg";
import inmobiliario from "../../../static/images/lca/inmobiliario.jpg";

const SectoresPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Sectores"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    Especialización y profundo conocimiento en los siguientes </h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">SECTORES
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/interior/infra.jpg"} alt={''}/>
                            </div>
                            <div className="col-12 col-lg-5 col-md-6 offset-lg-1">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Especialización estratégica </h5>
                                <p className="w-90">Nuestro equipo está constituido por profesionales de las
                                    finanzas con décadas de experiencia financiera a nivel global.
                                </p>
                                <p className="w-90">Desde nuestra creación, en LCA nos hemos especializado
                                    principalmente en los sectores infraestructura, energía, finanzas públicas e
                                    inmobiliario de la región.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}

                {/* start section */}
                <section className="wow animate__fadeIn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 sm-margin-30px-bottom">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                                    América Latina tiene una alta necesidad de infraestructura básica de
                                    calidad.</h5>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-7">
                                <div className="row row-cols-1 row-cols-sm-2">
                                    {/* start counter item */}
                                    <div
                                        className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Transporte</h5>


                                        <p className="w-85 md-w-100">Las autopistas de peaje y los aeropuertos, son una
                                            de las especialidades del grupo.</p>
                                    </div>
                                    {/* end counter item */}
                                    {/* start counter item */}
                                    <div className="col counter-style-01 last-paragraph-no-margin">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Energía</h5>

                                        <p className="w-85 md-w-100">Proyectos de generación, transmisión y distribución
                                            eléctrica, al igual que los proyectos de energía fósil.</p>
                                    </div>
                                    {/* end counter item */}
                                </div>
                            </div>
                            <div className="col-12" id="tab-sectores">
                                <div
                                    className="w-100 h-1px bg-medium-gray margin-7-rem-top margin-8-rem-bottom sm-margin-5-rem-tb"/>
                            </div>
                        </div>
                        <div className="row tab-sectores">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                {/* start tab navigation */}
                                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom">
                                    <li className="transportacion nav-item"><a
                                        className="transportacion nav-link active" data-toggle="tab"
                                        href="#planning-tab">Transportación</a></li>
                                    <li className="nav-item energia"><a className="energia nav-link" data-toggle="tab"
                                                                        href="#research-tab">Energía</a></li>
                                    <li className="nav-item telecomunicaciones"><a
                                        className="telecomunicaciones nav-link" data-toggle="tab"
                                        href="#telecomunicaciones-tab">Telecomunicaciones</a>
                                    </li>
                                    <li className="nav-item infraestructura"><a className="infraestructura nav-link"
                                                                                data-toggle="tab"
                                                                                href="#infraestructura-tab">Infraestructura
                                        social</a>
                                    </li>
                                    <li className="nav-item finanzas"><a className="finanzas nav-link" data-toggle="tab"
                                                                         href="#target-tab">Finanzas Públicas</a></li>
                                    <li className="nav-item sector"><a className="sector nav-link" data-toggle="tab"
                                                                       href="#inmobiliario-tab">Sector inmobiliario</a>
                                    </li>

                                </ul>
                                {/* end tab navigation */}
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="planning-tab"
                                         className="transportacion_content tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={transporte} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Transportación</h5>
                                                <p className="w-85 lg-w-100">Hemos asesorado en transacciones y
                                                    financiamientos de:
                                                </p>
                                                <ul className="list3">
                                                    <li>Autopistas</li>
                                                    <li>Aeropuertos</li>
                                                    <li>Trenes</li>
                                                    <li>Puentes</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="research-tab" className="energia_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/interior/energia.jpg"} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Energía y Oil & Gas</h5>
                                                <p className="w-85 lg-w-100">Hemos asesorado en transacciones y
                                                    financiamientos, así como evaluado inversiones en energía eléctrica
                                                    e hidrocarburos:
                                                </p>
                                                <ul className="list3">
                                                    <li>Generación de electricidad (CC, motores, solar, eólico)</li>
                                                    <li>Líneas de transmisión</li>
                                                    <li>Suministro calificado</li>
                                                    <li>Desarrolladores y empresas de energía en general</li>
                                                    <li>Terminales de almacenamiento (TARs)</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="telecomunicaciones-tab"
                                         className="telecomunicaciones_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={telecomunicaciones} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Telecomunicaciones</h5>
                                                <p className="w-85 lg-w-100">Contamos con experiencia asesorando en
                                                    financiamientos relacionados con telecomunicaciones, principalmente
                                                    relacionados con Fibra óptica, entre otros.
                                                </p>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="infraestructura-tab" className="infraestructura_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={social} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Infraestructura social</h5>
                                                <p className="w-85 lg-w-100">Asesoramos en financiamientos relacionados
                                                    con:</p>
                                                <ul className="list3">
                                                    <li>Edificios administrativos subnacionales</li>
                                                    <li>Registro público de la propiedad</li>
                                                    <li>Reclusorios</li>
                                                    <li>Hospitales</li>
                                                    <li>Centros educativos</li>
                                                    <li>Agua</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="target-tab" className="finanzas_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={finanzas} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Finanzas Públicas</h5>
                                                <p className="w-85 lg-w-100">Asesoramos a gobiernos y entidades
                                                    gubernamentales en:
                                                </p>
                                                <ul className="list3">
                                                    <li>Financiamientos y reestructuras de deuda pública</li>
                                                    <li>Bursátilizaciones de derechos e impuestos locales</li>
                                                    <li>Diseño y licitación de APPs</li>
                                                    <li>Asesoría en gestión de las finanzas públicas, calificación
                                                        crediticia y financiamiento de proyectos estratégicos
                                                    </li>
                                                </ul>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                    {/* start tab item */}
                                    <div id="inmobiliario-tab" className="sector_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                                 data-wow-delay="0.2s">
                                                <img src={inmobiliario} alt="" className={'w-100'}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Sector inmobiliario</h5>
                                                <p className="w-85 lg-w-100">Asesoramos y participamos en el desarrollo
                                                    de inmuebles estratégicos, incluyendo construcciones nuevas y
                                                    renovación de edificios de oficinas a residenciales y usos mixtos,
                                                    para venta y/o renta.
                                                </p>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contáctanos</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
            </>
        </Layout>
    )
}

export default SectoresPage
